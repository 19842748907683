body {
    background-color: silver !important;
}

.container {
    background-color: #ffffff;
    padding: 0px !important;
}

.header {
    /* turbine dark green */
    background-color: #1e2c22;
    padding: 15px 15px 15px 20px;
    flex-direction: row !important;

    .logo {
        height: 40px;
    }
}

.headline {
    padding: 40px;
    background-color: #000000;
    background-image: url(./images/headline_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .title {
        /* turbine beige */
        color: #eadec7;
        font-size: 45px;
        font-weight: 500;
    }

    .description {
        color: #ffffff;
        max-width: 450px;
        font-size: 16px;
    }
}

.content {
    padding: 40px;
    text-align: center;
    /* turbine light green */
    background-color: #a2afa1;

    .title {
        color: #000000d7;
        font-size: 35px;
        font-weight: 400;
    }

    .description {
        margin: auto;
        color: #ffffff;
        max-width: 550px;
        font-size: 16px;
    }

    h3 {
        margin-top: 20px;
    }

    p {
        color: #ffffff;
    }

    a {
        color: #1e2c22;
        text-decoration: none;
        font-weight: 600 !important;
    }

    .buttons {
        margin-top: 10px;

        .btn-primary {
            /* turbine yellow */
            background-color: #d8ed5c !important;
            color: #000000 !important;
            border-radius: 30px;
            border: 0;
            padding: 5px 30px;
            text-transform: uppercase;
            font-size: 14px;
        }
    }
}

.footer {
    background-color: #1e2c22;
    padding: 30px;

    .footer-col1 {
        margin-bottom: 15px;
    }

    .footer-col2 {
        margin-bottom: 15px;
        align-content: center;

        .title1 {
            color: #a2afa1;
            font-size: 30px;
            font-weight: 200 !important;
            line-height: 0.9;
        }

        .title2 {
            color: #eadec7;
            font-size: 30px;
            font-weight: 200 !important;
        }
    }

    .footer-col3 {
        margin-bottom: 15px;
        align-content: center;

        .title {
            color: #d8ed5c;
            font-weight: 200 !important;
        }

        .text,
        a {
            color: #ffffff;
            font-size: 14px;
            text-decoration: none;
            font-weight: 200 !important;
        }
    }

    .logo-symbol {
        height: 150px;
    }
}

.inter-headline {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 350;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
}

.inter-body {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 350;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
}

.inter-button {
    font-family: "Inter", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: 600 !important;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
}